.about-hero-img{
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255)), url('./res/about-img.jpg');
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: 50rem center;    
    align-self: flex-end;
    height: 100%;
    width: 100%;
}

.notfound{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.valuation-hero {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 85%), url('./res/valuation-bg.jpg');
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;    
}

.valuation-wrapper {
    gap: 1.5rem;    
}

.valuation-text {
    font-size: 3.5rem;
    font-weight: 900;
}

.valuation-sub {
    font-size: 1.5rem;
}

.valuation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.link-container{
    position: relative;
}

.nav-new-dot {
    color: #005CB8;
    position: absolute;
    font-weight: 900;
    left: 12px;
    top: -7px;
}

.valuation-form-inner {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 1rem;
}

.valuation-form-inner label{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.valuation-form-inner input{
    padding: 0.5rem;  
}

.valuation-button {
    display: flex;
    align-self: center;
    padding: 1.25rem;
}

@media (max-width:1460px) {
    .about-hero-img{
        background-position: 20rem center;    
    }
}

@media (max-width:900px) {
    .about-hero-img{
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgb(255, 255, 255) 70%), url('./res/about-img.jpg');
        background-position: center center;    
    }

    .about-hero-text h1{
        font-size: 4rem!important;
    }

    .valuation-text {
        font-size: 2.5rem;
    }

    .valuation-sub {
        font-size: 1rem;
    }
}
