
.admin-section{
    background-color: #f2f2f2;
    padding: 1rem;
    margin-top: 2rem;
}

.admin-section *{
    padding: 0.5rem 0;
}

.admin-section button{
    margin-right: 1rem!important;
    margin-top: 1rem!important;
}

.admin-nav {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid lightgray;
    padding: 1rem 0;
}

.admin-nav a.active {
    font-weight: bold;
    color: #013C77;
}

.flat-table-section {
    max-width: auto;
}

.flat-table-row {
    display: flex;
    gap: 0.5rem;
    font-size: 0.7rem;
    padding: 0.25rem;
}

.header-row {
    font-weight: bold;
    border-bottom: 1px solid black;
}

.flat-table-row:nth-child(even) {
    background-color: rgb(233, 233, 233);
}


.flat-table-row-item {
    min-width: 200px;
}

.long-item {
    min-width: 225px;
}

.short-item {
    min-width: 100px;
}

.xshort-item {
    min-width: 50px;
}

