.user-info{
    display: flex;
    align-items: center;
    padding: 0 0;
    padding-bottom: 2rem;
    border-bottom: 1px solid lightgray;
    width: 70%;
}

.user-info-section{
    margin-top: 2rem;
}

.user-info-tabs{
    display: flex;
    margin-bottom: 1rem;
}

.user-info-tab{
    width: 34%;
    text-align: center;
    background-color: #F2F2F2;
    height: 100%;
    padding: 1rem;
    font-weight: bold;
    outline: none;
    border: none;
    color: black;
}

.user-info-tab:hover{
    background-color: var(--main-theme-color-highlight);
    color: white;
}

.tab-selected{
    background-color: var(--main-theme-color);
    color: white;
}

.user-image{
    border-radius: 4rem;
}

.user-info-sub{
    margin-left: 1rem;
}

.warning-box{
    padding: 1rem 1rem;
    margin: 1rem 0;
    border: 2px solid rgb(255, 200, 99);
    background-color: rgb(253, 223, 167);
    border-radius: 0.5rem;
    font-weight: bold;
    max-width: fit-content;
}

.success-box{
    padding: 1rem 1rem;
    margin: 1rem 0;
    border: 2px solid rgb(99, 255, 107);
    background-color: rgb(187, 253, 167);
    border-radius: 0.5rem;
    font-weight: bold;
    max-width: fit-content;
}

.account-button{
    margin-left: 0!important;
    margin-bottom: 0rem;
    min-width: fit-content;
}

.log-out-button{
    margin-left: 0!important;
    margin-top: 0.5rem;
}

.user-nav-link{
    margin-left: 1rem;
    font-weight: bold;
}


.card-status{
    padding: 1rem 1rem;
    margin: 1rem 0;
    margin-bottom: 1rem;
    border: 2px solid rgb(255, 99, 99);
    background-color: rgb(253, 167, 167);
    border-radius: 0.5rem;
    font-weight: bold;
    max-width: fit-content;
}

.ml-i{
    margin-left: 1rem!important;
}

.exp-year{
    max-width: 100px;
    margin-left: 1rem;
}

.username-wrapper{
    display: flex;
    align-items: center;
}

.reverse-button{
    background: none!important;
    color: black!important;
    font-size: 1rem;
}

.reverse-button:hover{
    cursor: pointer;
    background: none!important;
}

@media (max-width:900px) {
    .user-info{
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
    }

    .user-info-sub{
        margin-left: 0;
        margin-top: 1rem;
    }

    .username-wrapper{
        justify-content: center;
    }

    .user-info-sub h2{
        text-align: center;
    }

    .user-info-sub p{
        text-align: center;
    }

    .log-out-button{
        max-width: 100%!important;
        width: 100%;
    }

    .form-align-row{
        flex-direction: column;
    }

    .form-second-input{
        margin-left: 0;
    }

    .exp-year{
        margin-left: 0;
    }

    .user-info-tab{
        white-space: normal;
        word-wrap: break-word;
        height: auto;
    }
}