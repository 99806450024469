@font-face {
    font-family: 'uknumberplateregular';
    src: url('./fonts/uknumberplate-webfont.woff2') format('woff2'),
         url('./fonts/uknumberplate-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-weight: 300;
}


:root{
    --plate-bg-color: #FBDA06;
}


#form-wrapper{
    background-color: white;
    width: 100%;
}

#form-section{
    width: 70%;
    max-width: 1100px;
    margin: auto;
}

#form-section h2{
    padding-top: 2rem;
}

#form-section p{
    padding-top: 0.5rem;
}


#form-section form{
    padding-top: 1rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    max-width: 650px;
}

#form-section label{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

#form-section input{
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.5rem;
    max-width: 300px;
    border: 1px solid black;
}

#form-section select{
    margin-top: 1rem;
    padding: 0.5rem;
    max-width: 300px;
}

.plate-input{
    background-color: var(--plate-bg-color);
    font-family:'uknumberplateregular';
    font-size: 45px!important;
    text-align: center;
    border-radius: 0.7rem;
    border: 4px solid black!important;
    max-width: 285px!important;
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}

.price-input{
    background: url('./res/pound.svg') no-repeat;
    background-size: 16px 14px;
    background-position: 5px 11px;
    padding-left: 25px!important;
    box-sizing: border-box;
}

.buyout-input{
    background: url('./res/pound.svg') no-repeat;
    background-size: 16px 14px;
    background-position: 5px 11px;
    padding-left: 25px!important;
    box-sizing: border-box;
}

.no-bg{
    background: none;
    padding-left: 0.5rem!important;
    background-color: white;
}

.file-input{
    padding: 0!important;
}

.tos-wrapper{
    display: flex;
    flex-direction: row;
}

.tos-input{
    margin-right: 0.5rem;
    padding: 0.5rem;
}

.tos-label{
    font-weight: normal;
    font-size: 12px;
}

.form-sub-text{
    font-weight: normal;
    color: rgb(143, 143, 143);
    font-size: 0.75rem;
    margin-top: 0.6rem;
}

.submit-input{
    padding: 0.75rem!important;
    border-radius: 1rem!important;
    font-weight: 700!important;
    background-color: var(--main-theme-color-lighter)!important;
    max-width: 110px!important;
    color: white!important;
    border: none!important;
}

.submit-input:hover{
    background-color: var(--main-theme-color-highlight)!important;
}

.drop-zone{
    min-width: 50px!important;
    max-width: 275px!important;
    margin-top: 1rem;
}

.profile-form{
    width: 100%!important;
    margin: 0!important;
    padding-bottom: 0rem!important;
    /*background-color: #F2F2F2;*/
    max-width: fit-content!important;
    border-radius: 1rem;
}

.profile-form-content{
    padding-top: 0!important;
    align-items: flex-start!important;
}

.rccs{
    margin: 0!important;
}

.form-align-row{
    display: flex;
    flex-direction: row;
}

.form-second-input{
    margin-left: 1rem;
}

.reserve-preview-popup{
    margin-left: 1rem;
    margin-top: 1rem;
    opacity: 0;
    transition: all 1s ease-in;
    font-weight: normal;
    border-left: 2px solid grey;
    padding-left: 0.5rem;
    color: gray;
    display: none;
    visibility: hidden;
}

.popup-show{
    display: block;
    visibility: visible;
    opacity: 1;
    transition: all .2s ease-in;
}

.duration-input{
    -webkit-appearance: none;
    width: 300px;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    border: 1px solid black;
    padding: 1rem;
    font-size: 1rem;
    color: black;
}

.duration-input-wrapper{
    display: flex;
    width: 300px;
    position: relative;
}

.duration-input-arrow{
    position: absolute;
    right: 5px;
    bottom: 5px;
    pointer-events: none;
}

@media (max-width:900px) {
    #form-section{
        width: 90%;
    }

    .reserve-preview{
        flex-direction: column;
    }

    .reserve-preview-popup{
        border: none;
        margin: 0;
        padding: 0;
        margin-top: 1rem;
        max-width: 300px;
    }
}