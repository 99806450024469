
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/lato-v23-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/lato-v23-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/lato-v23-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/lato-v23-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/lato-v23-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/lato-v23-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/lato-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/lato-v23-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/lato-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/lato-v23-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/lato-v23-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/lato-v23-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/lato-v23-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/lato-v23-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./fonts/lato-v23-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/lato-v23-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

:root{

  --main-theme-color: rgb(1, 39, 76);
  --main-theme-color-darker: rgb(1, 24, 48);
  --main-theme-color-lighter: rgb(1, 60, 119);
  --main-theme-color-highlight: rgb(0, 92, 184);
  --main-theme-color-highlight-lighter: rgb(0, 128, 255);

  --content-width-desktop: 70%;

  --section-white-darker: #F2F2F2;
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Lato';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html{
  background-color: var(--main-theme-color-darker);
}

body{
  background-color: var(--main-theme-color-darker);
}

a:hover, a:visited, a:link, a:active
{
  text-decoration: none;
}

a { color: inherit; } 


#wrapper{
  background-color: var(--main-theme-color-darker);
}

#hero{
  display: flex;
  min-height: 300px;
  max-height: 90vh;
  height: 100vh;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  background-size: cover;
  flex-wrap: nowrap;
}

#separator-wrapper{
  width: 100%;
  height: 90%;
  filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.5));
  z-index: 2;
  position: absolute;
}

#separator{
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: white;
  /*clip-path: polygon(0 0, 100% 0, 37% 100%, 0% 100%);*/
  clip-path: polygon(0 0,76% 0, 45% 100%, 0% 100%);
}

#hero-text{
  width: 70%;
  max-width: 1100px;
  background-color: transparent;
  color: black!important;
  z-index: 3;
  padding: 5em;
  position: absolute;
}

#hero-text h1{
  font-size: 75px;
  margin-bottom: 1rem;
  max-width: 800px;
  font-weight: 900;
  text-transform: capitalize;
}

.hero-large{
  font-size: 75px;
  margin-bottom: 1rem;
  max-width: 800px;
  font-weight: 900;
  text-transform: capitalize;
}

.hero-sub {
  text-decoration: none;
  font-weight: 400;
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 2rem;
  font-size: 24px;
}

#premier{
  color: var(--main-theme-color-highlight);
}

#hero-text h2{
  text-decoration: none;
  font-weight: 400;
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 2rem;
}

.listen-text{
  padding-top: 2rem;
}

#player{
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

#logo{
  display: block;
  max-width:160px;
  max-height:60px;
  width: auto;
  height: auto;
  margin-top: 0.1rem;
}

#header-wrapper{
  background-color: var(--main-theme-color-darker);
  width: 100%;
  z-index: 5;
  position: relative;
}

#header{
  background-color: var(--main-theme-color-darker);
  display: flex;
  padding: 1rem;
  font-size: 0.85rem;
  max-width: 1100px;
  width: 70%;
  margin: 0 auto;
  overflow: hidden;
}

#nav-container{
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

#nav{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
}

#nav2{
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
  margin-right: 3rem;
}

#nav-mobile{
  display: none;
  float: right;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

#nav-mobile-links{
  margin: 0 auto;
  width: 100%;
  z-index: 100;
  position: absolute;
  background-color: var(--main-theme-color-darker);
  display: none;
  visibility: hidden;
}



.layer-2{
  z-index: 200;
}

.mobile-link{
  background-color: var(--main-theme-color-darker);
  width: 100%;
  margin: auto;
  padding: 2rem;
  margin: 2rem;
}

.nav-toggle{
  border: none;
  background: none;
  color: white;
}

#nav-mobile:hover{
  opacity: 0.8;
}

#action-button{
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;
  font-weight: 700;
  background-color: var(--main-theme-color-lighter);
  color: white;
  max-width: fit-content;
  transition: background-color .2s ease-in;
}

.arrow{
  margin-left: 0.5rem;
  transition: margin-left .2s ease-in;
}

#action-button:hover .arrow{
  margin-left: 1rem;
  transition: margin-left .2s ease-in;
}


#action-button:hover{
  background-color: var(--main-theme-color-highlight);
  transition: background-color .2s ease-in;
}

#action-button-2{
  padding: 1rem;
  border-radius: 1rem;
  font-weight: 700;
  background-color: var(--main-theme-color-lighter);
  max-width: fit-content;
  color: white;
  transition: background-color .2s ease-in;
}

#action-button-2:hover .arrow{
  margin-left: 1rem;
  transition: margin-left .2s ease-in;
}

#action-button-2:hover{
  background-color: var(--main-theme-color-highlight);
  transition: background-color .2s ease-in;
}

.nav-button{
  padding: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  font-weight: 700;
  font-size: 1rem;
  color: white;
}

.lp-themed{
    border: 3px solid black;
    background-color: #FBDA06!important;
    text-align: center;
    font-family: 'uknumberplateregular';
    padding: 1rem;
    border-radius: 0.75rem;
    color: black;
    font-size: 1.2rem;
}

.nav-button-2{
  font-weight: 700;
  font-size: 1rem;
  color: white;
  background: none;
  border: none;
}

.nav-button-2-mobile{
  background: none;
  border: none;
  max-width: fit-content;
  margin-top: 0;
}

.rounded-bg{
  border-radius: 1rem;
  background-color: var(--main-theme-color-lighter);
}

.icon-bell-container{
  display: flex;
  margin-right: 1rem;
}

.icon-bell{
  filter: invert();
}

.icon-bell-dot{
  width: 8px;
  height: 8px;
  background-color: var(--main-theme-color-highlight-lighter);
  z-index: 5;
  border-radius: 1rem;
  position:absolute;
  margin-left: 12px;
}

.notification-button{
  background: none;
  border: none;
  display: flex;
}

.icon-bell:hover{
  filter: invert() brightness(80%);;
}

.user-img{
  max-height: 32px;
  max-width: 32px;
  border-radius: 1rem;
  margin-right: 0.5rem;
}

.user-dropdown{
  z-index: 10;
  position: absolute;
  width: 220px;
  border-radius: 0.5rem;
  padding-top: calc(306px);
  display: none;
  visibility: hidden;
}

.notifications-dropdown{
  z-index: 15;
  position: absolute;
  width: 300px;
  margin-top: calc(285px);
  margin-right: 20px;
  box-shadow: 2px 9px 13px -3px rgba(0,0,0,0.30);
  background-color: white;
}

.notification-area{
  max-height: 190px;
  min-height: 190px;
  overflow-y: scroll;
}

.notification{
  z-index: 15;
  display: flex;
  align-items: center;
}

.notification:hover{
  background-color: var(--section-white-darker);
}

.notification-text{
  padding: 1rem;
  font-weight: 500;
  font-size: 0.8rem;
}

.notifications-view-more{
  padding: 1rem;
  font-weight: bold;
  text-align: center;
}

.notifications-view-more:hover{
  background-color: var(--section-white-darker);
}

.user-dropdown:hover{
  display: block;
  visibility: visible;
}

.user-dropdown-link{
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: white;
  box-shadow: 2px 9px 13px -3px rgba(0,0,0,0.30);
}


.user-dropdown-link:hover{
  background-color: var(--section-white-darker);
}

.toggle-dropdown{
  width: auto;
  height: auto;
}

.toggle-dropdown:hover ~ .user-dropdown{
  display: block;
  visibility: visible;
}

.centered{
  display: flex;
  align-items: center;
}

.nav-button:hover{
  color:rgba(255, 255, 255, 0.822);
}

.rounded-bg:hover{
  background-color: var(--main-theme-color-highlight);
}

#about{

  position: relative;
  z-index: 4;
  display: block;
  background-color: var(--section-white-darker);
  clip-path: none;
}

#about-rating{
  display: block;
  height: 100%;
  max-height: fit-content;
  background-color: white;
}

.rating-text{
  font-family: Arial, Helvetica, sans-serif;
}

.tp_star__canvas{
  fill: #00b67a;
}

.ratings-component{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
}

.bottom-separator{
  height: 100px;
  background-color: #F2F2F2;
  position: absolute;
  top: -70px;
  width: 100%;
  z-index: 5;
  clip-path: polygon(100% 83%, 0% 100%, 100% 100%);
}

#about-content{
  width: var(--content-width-desktop);
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#about-content h1{
  margin-bottom: 2rem;
  font-weight: 900;
  color: var(--main-theme-color-lighter);
}

.section-title {
  margin-bottom: 2rem;
  font-weight: 900;
  color: var(--main-theme-color-lighter);
  font-size: 2rem;
}

.section-title-white {
  color: white;
  padding-bottom: 1.5rem;
  font-weight: 700;
}

#about-content-text{
  font-weight: 400;
  font-size: 18px;
  max-width: 70%;
  line-height: 1.8rem;
  padding-bottom: 2rem;
}


#about2{
  display: block;
  height: 100%;
  min-height: min-content;
  background-color:rgb(25, 26, 27);
  color: rgb(230,230,230);
  padding: 8rem;
}

#about2 h1{
  margin-bottom: 1.5rem;
}

#about2-content{
  display: block;
  flex-direction: row;
}

#about2-hero-container{
  display:flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1.5rem;
}


.about-reverse{
  background-color:rgb(25, 26, 27)!important;
}

.about2-hero{
  padding-right: 2rem;
  height: 45vh;
}


.about2-hero-img{
  width: 100%;
  height: 40%;
  background-image: url("res/about.jpg");
  background-size: cover;
}

.about2-hero-img2{
  width: 100%;
  height:40%;
  background-image: url("res/about2.jpg");
  background-size: cover;
}

#about2-hero-container h4{
  margin-top: 1.5rem;
  font-weight: 100;
  line-height: 1.4rem;
  font-size: 0.8rem;
}

.card-title{
  font-weight: 900;
  margin-left: 1rem;
  font-size: 1.5rem;
  color: white;
}

#about2-hero-container h2{
  margin-top: 1rem;
}

#about2-content-bottom{
  display: block;
  height: 100%;
  /* padding: 8rem; */
  background-color: var(--main-theme-color-darker);
  margin-top: 1rem;
  line-height: 1.4rem;
  overflow: hidden;
  width: var(--content-width-desktop);
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: white;
}

#about2-content-bottom h1{
  padding-bottom: 3rem;
}

#about2-content-container{
  display: flex;
  justify-content: space-between;
}

#about2-content-bottom p{
  margin-top: 1rem;
  color:#bbbbbb;
  font-weight: 400;
  font-size: 16px;
}

.about2-content-panel{
  width: 25%;
  justify-content: center;
  align-items: center;
}

.about2-content-panel p{
  margin-top: 1rem;
  color:#bbbbbb;
  font-weight: 600;
  font-size: 26px;
}

.content-panel-header{
  display:flex;
}

.content-panel-header h4{
  font-weight: 900;
  margin-left: 1rem;
  font-size: 1.5rem;
}

.link-icon{
  filter: invert(100%);
}

#sign-up-wrapper{
  display: block;
  background-color: var(--section-white-darker);
}

#sign-up{
  background-color: var(--section-white-darker);
  color: rgb(0, 0, 0);
  max-width: 1100px;
  width: var(--content-width-desktop);
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

#sign-up h1{
  color: var(--main-theme-color-lighter);
  padding-bottom: 2rem;
  font-weight: 900;
}


#sign-up-pane-container{
  display:flex;
}

.sign-up-pane{
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-right: 2rem;
  border-bottom: 1px solid gray;
}


.sign-up-pane h2{
  font-weight: 300;
}

#lower-bar-fixed{
  z-index: 100;
  position: fixed;
  height: 50px;
  background-color: red;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  background-image: linear-gradient( rgba(0,0,0,.7), rgba(0,0,0,.7) ),
  url("res/hero.jpg");
  background-size: cover;
  padding: 1rem;
}

#footer-wrapper{
  width: 100%;
  background-color: var(--main-theme-color-darker);
}

#footer{
  background-color: var(--main-theme-color-darker);
  width: var(--content-width-desktop);
  max-width: 1100px;
  margin: 0 auto;
}

#footer #logo{
  margin-top: 3rem;
}

#footer-links{
  display:flex;
  padding-top: 3rem;
  color: rgb(230,230,230);
  padding-bottom: 1rem;
}

.footer-column{
  padding: 3rem;
  padding-left: 0rem;
  padding-right: 8rem;
}

.footer-column h2{
  font-size: 1rem;
}

.footer-column p{
  margin-top: 1.5rem;
  font-size: 0.8rem;
}

#social-container{
  display:flex;
  padding-bottom: 2rem;
}

.social-icon{
  padding: 1rem;
  padding-left: 0rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: invert();
}

.contact-icon{
  filter:none;
}

#subtext{
  font-size: 0.8rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

#subtext p{
  color: floralwhite;
}

.mobile-notifications{
  display: none;
  visibility: hidden;
}

.cookie-popup{
  display: flex;
  align-items: center!important;
  justify-content: center;
  flex-direction: column;
}

.cookie-text{
  flex: 0!important;
  margin: 8px!important;
  text-align: center!important;
}

.cookie-button{
  margin: 0!important;
  margin-bottom: 8px!important;
}

.cookie-button:hover{
  background-color: var(--main-theme-color-highlight)!important;
}

@media (max-width:1460px) {
  #hero-text{
    width: 85%;
    padding: 0;
  }

  .hero-large{
    font-size: 4rem;
    max-width: 600px;
  }

  .hero-sub{
    font-size: 1.5rem;
    max-width: 400px;
  }

  #header{
    width: 85%;
  }

  #about-content {
    width: 85%;
  }

  #about2-content-bottom{
    width: 85%;
  }

  #sign-up{
    width: 85%;
  }

  #footer{
    width: 85%;
  }
}

@media (max-width:900px) {
  .hero-large{
      font-size: 62px;
      margin-bottom: 1rem;
  }

  .notifications-dropdown{
    z-index: 15;
    position: absolute;
    width: 300px;
    margin-top: calc(319px);
    margin-right: 20px;
    box-shadow: 2px 9px 13px -3px rgba(0,0,0,0.30);
    background-color: white;
  }

  .mobile-notifications{
    display: block;
    visibility: visible;
    margin-top: 0px;
    width: 100%;
  }

  #nav-mobile-links{
    display: block;
    visibility: visible;
  }

  .hero-sub{
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-bottom: 2rem;
  }

  #hero-text{
    width: 85%;
    padding: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  #hero{
    align-items: flex-start;
    width: 90%;
    min-height: min-content;
    min-height: 560px;
  }

  #separator{
    max-height:500px;
    clip-path: polygon(0px 0px, 100% 0px, 100% 80.23%, 0% 92%);
  }


  #header{
    padding-right: 2rem;
    overflow: hidden;
    width: 85%;
  }

  #header-wrapper{
    overflow: hidden;
  }


  #nav{
      visibility: hidden;
      display: none;
  }

  #nav2{
      visibility: hidden;
      display: none;
  }
  
  #nav-mobile{
      visibility: visible;
      display: flex;
  }

  #nav-container{
      justify-content: flex-end;
  }

  #about-content{
      padding: 1rem;
      padding-bottom: 3rem;
      margin-bottom: 1rem;
      margin: 0 0;
      margin-left: 1rem;
      margin-right: 0;
      width: 85%;
      padding-top: 3rem;
  }

  #about-content-text{
    max-width: 100%;
  }

  #about-content h3{
      font-weight: 100;
      font-size: 1rem;
      max-width: 100%;
      line-height: 1.4rem;
      margin-bottom: 2rem;
  }

  .section-title-top{
      margin-top: 3rem;
  }

  #about{
      display: block;
      height: 100%;
      max-height: fit-content;
      clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%);
      -webkit-box-shadow: -3px -1px 16px 2px #000000; 
      box-shadow: -3px -1px 16px 2px #000000;
  }

  #about2{
      display: block;
      height: 100%;
      padding:2rem;
  }

  #about2 h1{
      text-align: center;
      margin-top: 2rem;
  }

  #about2-content{
      justify-content: center;
      align-items: center;
  }

  #about2-hero-container{
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
  }

  .about2-hero{
      padding: 0rem;
      height: 25%;
      margin-bottom: 1rem;
  }

  .about2-hero-img{
      height: 55vh;
  }

  .about2-hero-img2{
      height: 55vh;
  }

  #about2-content-bottom h1{
    line-height: 2.5rem;
  }

  #about2-content-bottom{
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 1rem;
    margin: 0 0;
    margin-left: 1rem;
    margin-right: 0;
    width: 80%;
    line-height: 1.8rem;
  }

  #sign-up{
      padding: 1rem;
      padding-top: 3rem;
      padding-bottom: 3rem;
      margin-bottom: 1rem;
      margin: 0 0;
      margin-left: 1rem;
      margin-right: 0;
      width: 80%;
      line-height: 1.8rem;
  }

  #sign-up h1{
    line-height: 2.5rem;
  }

  #footer{
    margin: 0 0;
    padding-left: 2rem;
  }

  #about2-content-container{
      flex-direction: column;
  }

  .about2-content-panel{
      width: 100%;
      margin-bottom: 2rem;
  }

  #sign-up-pane-container{
      flex-direction: column;
  }
  
  #footer #logo{
    
  }

  #footer-links{
      flex-direction: column;
      
  }

  #social-container{
    
  }

  #subtext{
      
  }
}

@media (max-width:590px) {
  .hero-large{
      font-size: 44px;
      margin-bottom: 1rem;
  }
}

@media (max-width:321px) {
  .hero-large{
      font-size: 28px;
      margin-bottom: 1rem;
  }
}