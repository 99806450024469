.error-wrapper{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-content{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
}

.error-logo{
    width: 300px;
    padding: 1rem;
}

.error-info{
    font-weight: bold;
    padding: 1rem;
    font-size: 1.1rem;
    text-align: center;
}

.error-back{
    text-decoration: underline;
    color: rgb(151, 151, 151);
}

.error-back:hover{
    color: blue;
}

