@font-face {
    font-family: 'uknumberplateregular';
    src: url('fonts/uknumberplate-webfont.woff2') format('woff2'),
         url('fonts/uknumberplate-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-weight: 300;
}

.marquee-wrapper{
    background:transparent;
    text-align:center;
    overflow: hidden;
    background-color: white;
  }
  .marquee-wrapper .container{
    overflow:hidden;
  }
  .marquee-inner span{
    float:left;
    width:50%;
  }
  .marquee-wrapper .marquee-block{
    --total-marquee-items: 8!important;
    height: 150px;
    width: calc(420px * (var(--total-marquee-items)));
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    margin: 20px auto;
    background:transparent;
    padding: 10px 0;
  }
  .marquee-inner{
    display: block;
    width: 200%;
    position: absolute;
  }
  .marquee-inner p{
    font-weight: 700;
    font-size: 55px;
    font-family:'uknumberplateregular';
    -webkit-user-select: none; /* Safari */        
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
  }
  .marquee-inner.to-left{
    animation: marqueeLeft 45s linear infinite;
  }
  .marquee-inner.to-right{
    animation: marqueeRight 45s linear infinite;
  }
  .marquee-item{
    outline: 6px solid black;
    width: 400px;
    height: 120px;
    margin: 0 10px;
    float: left;
    transition: all .2s ease-out;
    background:#FBDA06;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 9px 13px -3px rgba(0,0,0,0.50);
  }
  @keyframes marqueeLeft{
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
  @keyframes marqueeRight{
    0% { 
      left: -100%; 
    }
    100% {
     left: 0; 
    }
  }