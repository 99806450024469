#plate-view-wrapper{
    background-color: white;
    width: 100%;
}

#plate-view{
    max-width: 1000px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.plate-view-sub{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sub-right{
    margin-top: 1rem;
}

.plate-info{
    display: flex;
    background-color: #F2F2F2;
    border-radius: 0.5rem;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    margin: 0 auto;
    justify-content: center;
    justify-content: space-evenly;
}

.action-icon-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;
}

.bid-table-header{
    background-color: var(--main-theme-color);
    color: white;
}

.action-icon{
    background: none;
    border: none;
    display: flex;
    align-items: center;
}

.action-icon img{
    opacity: 0.5;
    filter: invert();
    cursor: pointer;
}

.action-icon:hover img{
    opacity: 1.0;
    filter: invert(20%) sepia(97%) saturate(2056%) hue-rotate(197deg) brightness(98%) contrast(102%);
}

.info-text-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 33%;
    padding: 1rem;
}

.info-text{
    padding: 1rem 0;
    padding: 1rem;
    width: 33%;
    text-align: center;
}

.info-text-wrapped{
    width: auto;
    padding: 0;
}

.icon-text-align{
    display: flex;
    flex-direction: row;
}

.verified-icon{
    margin-left: 0.25rem;
    top: 3px;
    position: relative;
}

.pv-top{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.pv-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 50%;
}

#main-plate{
    border: 7px solid black;
    background-color: #FBDA06;
    text-align: center;
    font-family: 'uknumberplateregular';
    font-size: 5rem;
    padding: 2rem;
    border-radius: 0.75rem;
    margin: auto;
    min-width: 400px;
    max-width: fit-content;
}

#plate-container{
    width:calc(800px - 10vw);
    max-width: 100%;
    min-width: 60%;
    margin: 0 auto;
}

.plate-container-left{
    width: 100%;
}
.plate-container-right{
    padding-left: 1rem;
}

#plate-name{
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bid-section{
    background-color: #F2F2F2;
    border-radius: 0.5rem;
    flex-direction: column;
}

#controls{
    display: flex;
    justify-content: center;
    width: 100%;
}

#controls input{
    padding: 0.75rem;
    border-radius: 1rem;
    border: 1px solid rgb(231, 231, 231);
    font-size: 1rem;
    font-weight: normal;
    min-width: fit-content;
}

.theme-button{
    margin-left: 1rem;
    padding: 0.75rem!important;
    border-radius: 1rem!important;
    font-weight: 700!important;
    background-color: var(--main-theme-color-lighter)!important;
    color: white!important;
    border: none!important;
    max-width: fit-content;
}

.btn-xl{
    max-width: 400px!important;
    margin: 1rem;
    margin-left: 0rem;
}

.btn-last{
    margin-left: 4rem;
}

.sub-button{
    background-color: lightgray!important;
}

#auction-info{
    display: flex;
    padding-top: 1rem;
    padding-bottom: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
}


.plate-desc-wrapper{
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.plate-desc {
    color: gray;
    font-style: italic;
    margin-top: 0.5rem;
    text-align: center;
}

.info-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 32%;
    justify-content: center;
}

.info-important{
    font-size: 1.3rem;
    font-weight: 900;
}

#auction-info p{
    font-size: 1.2rem;
    margin-right: 1rem;
    margin: 0 auto;
}

.info-sub{
    font-size: 1rem;
    color: rgb(160, 160, 160);
    margin: 0 auto;
}

.status-box{
    background-color: white;
    padding: 1rem;
    border: 2px solid black;
}

.bid-history{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.bid-history-header{
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    margin: 0 auto;
}

.table-overflow-scrollable{
    display: flex;
    flex-direction: row;
    max-height: 200px;
    overflow-y: scroll;
    min-height: 200px;
}

.bid-history-table{
    width: 100%;
    margin: 0;
    border: none!important;
}

.bid-history-table td{
    margin-left: 1rem;
    border: none!important;
}

.bid-history-table tr{
    border: none!important;
}


.bid-history-notice{
    padding-bottom: 2rem;
    color: green;
}

.bid-history-item{
    display: flex;
    padding: 1rem;
    background-color: white;
}


.inner-item{
    background: none;
    padding: 0;
}

.bid-history-item td{
    text-align: left;
}


.bid-history-item:nth-child(even){
    background-color: #F2F2F2;
}

.highest-bid {
    animation: color-change 1s;
}

.plate-bid-section-header{
    display: flex;
    justify-content: space-between;
    padding: "1rem";
    background-color: var(--main-theme-color);
    color: white;
    padding: 1rem;
    padding-bottom: 0.75rem;
}

.bid-count{
     padding: 1rem;
}

.gallery-img{
    margin-left: 1rem;
    margin-bottom: 1rem;
}
  
@keyframes color-change {
    0% { color: rgb(0, 228, 0); }
    100% { color: black; }
}

.plate-mobile-header{
    position: fixed;
    height: 50px;
    top: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    padding-bottom: 1.5rem;
    visibility: hidden;
    display: none;
    background-color: white;
    box-shadow: 2px 9px 13px -3px rgba(0,0,0,0.50);
 
    align-items: center;
    justify-content: center;
}

.small-plate{
    border: 5px solid black;
    background-color: #FBDA06;
    text-align: center;
    font-family: 'uknumberplateregular';
    font-size: 1.5rem;
    padding: 1rem;
    border-radius: 0.75rem;
    min-width: 100px;
    max-width: fit-content;
}

.top-notification{
    border-radius: 0.75rem;
    padding: 1rem;
    margin-bottom: 2rem;
}

.tp-ok{
    border: 3px solid green;
    background-color: #a8ffa8;
}

.tp-warn{
    border: 3px solid orange;
    background-color: rgb(255, 222, 162);
}

.tp-error{
    border: 3px solid red;
    background-color: rgb(255, 179, 179);
}


.popup-content {
    border-radius: 1rem;
    width: auto!important;
    transition: max-height .2s linear;
}

.controls-before-container{
    width: 100%;
    padding: 0;
    padding-bottom: 1rem;
}

.confirm-button{
    border: none;
    background-color: var(--main-theme-color-highlight);
    color: white;
    padding: 1rem;
    font-weight: 700;
    font-size: 1.05rem;
    border-radius: 1rem;
    margin: 0 1rem;
    min-width: 150px;
}

.confirm-button:hover{
    cursor: pointer;
    background-color: var(--main-theme-color-lighter);
    
}

.bid-popup-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
}

.bid-amount-input{
    background: url('./res/pound.svg') no-repeat;
    background-size: 16px 14px;
    background-position: 5px 11px;
    padding-left: 25px!important;
    box-sizing: border-box;   
    border-radius: 0.5rem;
    border: 1px solid black;
}

.close-popup{
    border: none;
    padding: 1rem;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    color: black;
}

.close-popup:hover{
    background-color: lightgray;
}

.bid-popup-controls{
    display: flex;
}

.fees{
    padding: 1rem;
}

.fee-table tr{
    padding: 1rem;
}

.fee-table td{
    padding: 1rem 0;
}

.fee-table{
    width: 100%;
}

.fees-value{
    text-align: end!important;
}

.modal-relist-wrapper{
    width: 600px!important;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: center;
}

.popup-overlay{
    overflow-y: auto;
}

.date-info{
    margin: 1rem;
    margin-bottom: 0;
    border: 1px solid black;
    background-color: white;
}

@media (max-width:900px) {
    .modal-relist-wrapper{
        width: 100%!important;
    }

    .plate-mobile-header{
        display: flex;
        visibility: visible;
    }

    .popup-content {
        width: 100%!important;
    }

    #plate-view{
        width: 90%;
    }

    #main-plate{
        min-width: fit-content;
        max-width: 300px;
        font-size: 3rem;
    }

    .bid-section{
        margin-left: 0rem;
    }

    .plate-info{
        flex-direction: column;
        justify-content: center;
        border: none;
    }

    .info-text{
        text-align: center;
        border-bottom: 1px solid rgb(209, 209, 209);
        width: auto;
    }

    .info-text-wrapper{
        padding: 1rem;
        text-align: center;
        border-bottom: 1px solid rgb(209, 209, 209);
        width: auto;
    }

    .action-icon-container{
        justify-content: flex-start;
    }

    #auction-info{
        flex-direction: column;
    }

    .confirm-button{
        min-width: 110px;
    }

    .info-container{
        padding: 1rem;
        text-align: center;
        width: auto;
    }

    .bid-amount-input{
        min-width: 0!important;
        max-width: 200px;
        font-size: 16px!important;
        padding: 0.5rem;
    }

    .bid-history-item{
        display: flex;
        flex-direction: column;
    }

    .bid-history-item td{
        text-align: left;
    }

    .theme-button-mobile{
        margin-left: 0.25rem!important;
    }

    .bid-form{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #controls{
        margin: 0;
        display: flex;
        width: 100%;
    }

    .bid-count{
        padding: 1rem;
    }

    .buy-now{
        height: 45px;
    }

}