.faq-question{
    padding: 1rem;
    padding-left: 0;
    font-weight: bold;
    cursor: pointer;
}

.question-q{
    margin-right: 0.5rem;
    font-size: 1.1rem;
    color: rgb(111, 111, 255);
}

.faq-answer{
    padding: 1rem;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    line-height: 1.5rem;
}