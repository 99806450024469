#plate-wrapper{
    background-color: white;
    width: 100%;
}

#plate-section{
    background-color: white;
    width: 70%;
    max-width: 1100px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

#plate-section h1{
    font-weight: 900;
}

#header-section{
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
}

#header-section form{
    margin-left: 2rem;
}

#plate-wrapper form{
    display: flex;
    align-items: center;
}

th input{
    padding: 0.75rem;
    border-radius: 1rem;
    border: 1px solid rgb(231, 231, 231);
    width: 235px;
}

th select{
    padding: 0.75rem;
    border-radius: 1rem;
    border: 1px solid rgb(231, 231, 231);
}

.icon{
    margin-left: 1rem;
    fill:rgb(231, 231, 231);
}

.icon:hover{
    fill:var(--main-theme-color-lighter);
}

.theme-button:hover{
    background-color: var(--main-theme-color-highlight)!important;
}


.plate{
    display: flex;
    width: 100%;
    max-height: 100px;
    padding: 1rem;
    border-bottom: 1px solid rgb(190, 190, 190);
    align-items: center;
}

.plate-name{
    background-color: var(--plate-bg-color);
    font-family:'uknumberplateregular';
    font-size: 45px!important;
    text-align: center;
    border-radius: 0.7rem;
    padding: 1rem;
    width: 225px;
    border: 4px solid black;
    position: relative;
}

.plate-name-p {
    font-family:'uknumberplateregular';
    font-size: 45px!important;
    text-align: center;
}

.plate-name-reserve {
    font-size: 14px;
    position: absolute;
    right: -4px;
    bottom: 10;
    top: 5;
    margin-top: 0.20rem;
    font-weight: bold;
    background-color: rgb(0, 190, 0);
    padding: 0.4rem;
    border: 2px solid black;
    border-radius: 0.25rem;
    color: white;
}

tr{
    border-bottom: 1px solid black;
}

.plate-view{
    padding: 0.75rem!important;
    border-radius: 1rem!important;
    font-weight: 700!important;
    background-color: var(--main-theme-color-lighter)!important;
    max-width: 110px!important;
    color: white!important;
    border: none!important;
    margin-left: 2rem;
}

.plate-view:hover{
    background-color: var(--main-theme-color-highlight)!important;
}

table{
    border-spacing: 0px;
}

th{
    padding-left: 2rem;
    padding-right: 2rem;
}

td:not(:first-child) {
    border-left: 1px solid rgb(231, 231, 231);
    text-align: center;
}

/*tr:hover:not(:first-child){
    background-color: var(--section-white-darker);
}*/


.field{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 16px;
}

.field-h{
    margin: 2rem;
    font-size: 16px;
    font-weight: bold;
}

.no-pad-left{
    padding-left: 0;
}

.bid{
    margin-left: calc(225px);
}

.table-control{
    display: flex;
    align-items: center;
    position: relative;
}

.dateless-wrapper{
    display: flex;
    align-items: center;
    position: absolute;
    left: 150px;
    color: black;
    font-weight: bold;
}

.dateless-check{
    width: auto;
    margin-left: 0.5rem;
}

.dateless-check-label{
    font-size: 0.75rem;
    font-weight: normal;
    width: 80px;
    font-weight: bold;
}

.plate-mobile-info{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    width: 90%;
    padding-top: 0;
    font-weight: bold;
    display: none;
    visibility: hidden;
}

.mobile-visible{
    display: none;
    visibility: hidden;
}

.mobile-time{
    display: flex;
    align-items: center;
}

.mobile-time span{
    margin-left: 0.5rem;
}

.mobile-price{
    font-size: 1.1rem;
}

.ok-field{
    color:rgb(0, 158, 0);
    font-weight: bold;
    padding: 1rem;
}

.warning-field{
    color: orange;
    font-weight: bold;
    padding: 1rem;
}

.error-field{
    color: red;

    font-weight: bold;
    padding: 1rem;
}

.reverse-button{
    background: none!important;
    color: black!important;
    font-size: 1rem;
}

.reverse-button:hover{
    cursor: pointer;
    background: none!important;
}

@media (max-width:1440px) {
    .plate-name-reserve {
        font-size: 12px;
        position: absolute;
        right: -4px;
        bottom: 10;
        top: 5;
        margin-top: 0.15rem;
        padding: 0.3rem;
    }

    #plate-section{
        width: 85%;
        margin: 0 auto;
    }
}

@media (max-width:900px) {
    th{
        padding-right: 0;
    }

    .t-header{
        margin-bottom: 2rem;
    }

    .table-control{
        padding-bottom: 1rem;
    }

    .search-bar{
        border-radius: 0;
    }

    #plate-section{
        width: 85%;
        margin: 0 auto;
    }
    
    .mobile-hidden{
        display: none;
        visibility: hidden;
    }

    #header-section{
        margin: 0 auto;
        text-align: center;
    }

    .plate-table{
        margin: 0 auto;
    }

    .mobile-visible{
        display: block;
        visibility: visible;
    }

    .search-bar{
        margin-right: 0.5rem;
    }

    .plate-mobile-info{
        display: flex;
        visibility: visible;
    }

   

}